//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import { timer } from 'rxjs';
import { mapActions, mapGetters } from 'vuex';

const minMinute = 1;

export default {
  components: {},
  props: {},
  data() {
    return {
      loading: false,
      scheduling: {
        starting_time: null,
        status: 'DRAFT',
        params: {}
      },
      statusInclude: 'page|code',
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px'
      },
      acceptableDate: moment()
    };
  },

  computed: {
    ...mapGetters('project', ['projectById']),
    project() {
      return this.projectById(this.$route.params.projectId);
    },
    formItemLayout() {
      return {
        labelCol: { xs: { span: 8 }, sm: { span: 3 }, lg: { span: 3 } },
        wrapperCol: { xs: { span: 16 }, sm: { span: 18 }, lg: { span: 18 } }
      };
    },
    formTailLayout() {
      return {
        labelCol: { xs: { span: 8 }, sm: { span: 3 }, lg: { span: 3 } },
        wrapperCol: {
          xs: { span: 16, offset: 8 },
          sm: { span: 20, offset: 3 },
          lg: { span: 10, offset: 3 }
        }
      };
    },
    rules() {
      if (this.isUnScheduled) {
        return {
          starting_time: [
            {
              validator: (rule, value, callback) => {
                if (value && moment(value) < this.acceptableDate) {
                  callback(
                    new Error(
                      this.$t('format.scheduling', {
                        minSending: this.acceptableDate.format('YYYY-MM-DD HH:mm')
                      })
                    )
                  );
                } else {
                  callback();
                }
              },
              trigger: ['change', 'blur']
            }
          ]
        };
      }
      return {};
    },
    isUnScheduled() {
      return this.scheduling?.status === 'DRAFT';
    },
    isInProcessScheduled() {
      return this.scheduling?.status === 'INPROCESS';
    },
    isDoneScheduled() {
      return this.scheduling?.status === 'DONE';
    },
    isIncludePublishStatus() {
      return this.scheduling?.params?.is_public ?? false;
    },
    currentDateTime() {
      return moment();
    },
    countdownFormat() {
      let format = {
        en: {
          day: 'D [days] HH [hours] mm [minutes] ss [seconds]',
          hour: 'HH [hours] mm [minutes] ss [seconds]',
          minute: 'mm [minutes] ss [seconds]',
          second: 'ss [seconds]'
        },
        ja: {
          day: 'DD日HH時間mm分ss秒',
          hour: 'HH時mm分',
          minute: 'mm分ss秒',
          second: 'ss秒'
        }
      };
      const remainingDay =
        -1 * this.currentDateTime.diff(this.project.scheduling.starting_time, 'days');
      const remainingHour =
        -1 * this.currentDateTime.diff(this.project.scheduling.starting_time, 'hours');
      const remainingMinute =
        -1 * this.currentDateTime.diff(this.project.scheduling.starting_time, 'minutes');
      if (remainingDay > 0) return format[this.$i18n.locale].day;
      else if (remainingHour > 0) return format[this.$i18n.locale].hour;
      else if (remainingMinute > 0) return format[this.$i18n.locale].minute;
      return format[this.$i18n.locale].second;
    },
    publishHtml() {
      let status = this.scheduling?.params?.is_public;
      let fromHTML = null;
      let toHTML = null;
      if (status) {
        fromHTML = `<span style="color: #6596eb;">${this.$t('Private')}</span>`;
        toHTML = `<span style="color: #4a802d;font-size:26px">${this.$t('Public')}</span>`;
      } else {
        fromHTML = `<span style="color: #4a802d">${this.$t('Public')}</span>`;
        toHTML = `<span style="color: #6596eb;font-size:26px">${this.$t('Private')}</span>`;
      }
      return `<span>${this.$t('schedulePublishChangeHTML', {
        fromStatus: fromHTML,
        toStatus: toHTML
      })}</span>`;
    }
  },

  created() {
    // Auto set starting time to 1 minute after current time
    this.$subscribeTo(timer(1000, 1000), () => {
      this.acceptableDate = moment().add(minMinute, 'minutes');
      if (this.scheduling?.starting_time) {
        if (this.scheduling.starting_time < this.acceptableDate) {
          this.scheduling.starting_time = this.acceptableDate;
        }
      }
    });
    this.initial();
  },
  methods: {
    ...mapActions('project', ['updateProjectById']),
    initial(scheduling = this.project.scheduling) {
      this.scheduling = {
        starting_time: scheduling?.starting_time
          ? moment(scheduling?.starting_time)
          : this.acceptableDate,
        status: scheduling?.status || 'DRAFT',
        params: {
          is_public: scheduling?.params?.is_public ?? !this.project.is_public,
          includes: scheduling?.params?.includes ?? ['page', 'code']
        }
      };
      this.statusInclude = this.scheduling?.params?.includes?.join('|');
    },
    async handleSetupSchedule(actionType = 'CREATE') {
      this.loading = true;
      try {
        if (actionType === 'CREATE')
          this.$refs.schedulingForm.validate(valid => {
            if (!valid) {
              throw new Error('invalidData');
            }
          });
        const payload = {
          scheduling: {
            starting_time: this.scheduling.starting_time.format(),
            params: { ...this.scheduling.params, includes: this.statusInclude.split('|') }
          },
          actionType
        };
        const updatedProject = await this.$s.api.project.setUpSchedule(this.project.id, payload);
        if (!this.isDoneScheduled) this.$notification.success({ message: this.$t('Successful') });
        this.updateProjectById(updatedProject);
        this.initial(updatedProject.scheduling);
      } catch (error) {
        this.$notification.error({
          message: this.$t('Error'),
          description: error.message
        });
      }
      this.loading = false;
    },
    disabledStartingDate(current) {
      // Only allow from today at 00:00 and future
      const today = moment().startOf('day');
      return current && current < today;
    },

    disabledEndDate(current) {
      const startingTime = moment(this.scheduling.starting_time);
      return current && current <= startingTime;
    },

    disabledDateTime() {
      return {
        disabledHours: () => [],
        disabledMinutes: () => []
      };
    }
  }
};
